import Link from "next/link";
import { FC, JSXElementConstructor } from "react";

import BarronsLogo from "../../public/webstatic/svg/barrons.svg";
import BenzingaLogo from "../../public/webstatic/svg/benzinga.svg";
import NYTLogo from "../../public/webstatic/svg/nyt.svg";
import VentureBeatLogo from "../../public/webstatic/svg/venturebeat.svg";
import YahooFinanceLogo from "../../public/webstatic/svg/yahoofinance.svg";

const PressLinks = [
  {
    name: "NY Times",
    logo: NYTLogo,
    url: "https://www.nytimes.com/2024/05/11/your-money/investment-portfolio-reflect-values.html",
  },
  {
    name: "Barron's",
    logo: BarronsLogo,
    url: "https://www.barrons.com/articles/direct-indexing-taxes-540e0de2",
  },
  {
    name: "Venture Beat",
    logo: VentureBeatLogo,
    url: "https://venturebeat.com/ai/frec-steps-out-of-stealth-with-26m-in-funding-aiming-to-democratize-sophisticated-investing-with-ai/",
  },
  {
    name: "Benzinga",
    logo: BenzingaLogo,
    url: "https://www.benzinga.com/fintech/23/10/35058872/exclusive-frec-launches-direct-indexing-takes-first-mover-advantage-insights-from-ceo-mo-al",
  },
  {
    name: "Yahoo Finance",
    logo: YahooFinanceLogo,
    url: "https://finance.yahoo.com/news/frec-emerges-stealth-26-4-120000672.html",
  },
];

export const Press: FC = () => {
  return (
    <div className="bg-frecBeige flex flex-col items-center gap-8 px-4 pt-14 pb-6 lg:gap-10">
      <span className="text-frecXDarkGray text-14 lg:text-16 font-medium uppercase">
        Featured in
      </span>
      <div className="flex flex-wrap items-center justify-center gap-8 lg:gap-16">
        {PressLinks.map(({ logo, url }, i) => (
          <PressLogo logo={logo} url={url} key={i} />
        ))}
      </div>
    </div>
  );
};

const PressLogo: FC<{
  logo: JSXElementConstructor<React.SVGProps<SVGSVGElement>>;
  url: string;
}> = ({ logo: Logo, url }) => {
  return (
    <Link
      className="h-7 cursor-pointer opacity-60 duration-300 hover:opacity-100"
      href={url}
      target="_blank"
      rel="noreferrer"
    >
      <Logo className="h-full w-full" />
    </Link>
  );
};
